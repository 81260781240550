import * as React from "react"
import { IconProp } from "./interface"

function MailIcon({ height = "1rem", width = "1rem", fill = "#283044" }: IconProp) {
  return (
    <svg viewBox="0 0 141.73 141.73" height={height} width={width}>
      <path
        d="M139 54c-6.35 4.66-14.35 10.36-42.51 29.51C90.94 87.43 80.73 96 71.06 96c-9.93 0-19.87-8.54-25.67-12.42C17.23 64.4 9.23 58.7 2.88 54A1.55 1.55 0 00.4 55.34v52.8c0 7 5.79 12.42 13.25 12.42h114.83c7.18 0 13.25-5.43 13.25-12.42v-52.8A1.76 1.76 0 00139 54zM71.06 87.43c6.35.26 15.46-7.51 20.16-10.61C127.93 52 130.69 49.64 139 43.43a5.79 5.79 0 002.76-4.92V33.6c0-6.73-6.07-12.43-13.25-12.43H13.65C6.19 21.17.4 26.87.4 33.6v4.91a5.55 5.55 0 002.48 4.92c8.28 6.21 11 8.54 47.76 33.39 4.69 3.1 13.8 10.87 20.42 10.61z"
        fill={fill}
      />
    </svg>
  )
}

export default MailIcon