import React from 'react'
import Cover from '../Cover'
import DigitalServices from './DigitalServices'
import OurClients from './OurClients'
import AITechnology from './Technology'
import MediaSource from './MediaSources'

function Main() {
  return (
    <div>
      <Cover />
      <MediaSource />
      <DigitalServices />
      <AITechnology />
      <OurClients />
    </div>
  )
}

export default Main
