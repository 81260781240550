import React from 'react'
import styled from 'styled-components'
import LocationIcon from "../../assets/icons/LocationIcon"
import MailIcon from "../../assets/icons/MailIcon"
import PhoneIcon from "../../assets/icons/PhoneIcon"

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  ul {
    margin-top: 4rem;
    padding: 0px;
    list-style-type: none;
    li {
      margin-left: 2rem;
      height: fit-content;
      display: flex;
      align-items: center;
      margin-bottom: 3rem;
      p {
        margin-left: 2rem;
      }
    }
  }
  img {
    margin-top: -3rem;
    width: 300px;
    height: 300px;
  }
`


function Details() {
  return (
    <DetailsContainer>
      <ul>
        <li>

          <MailIcon fill="#3FC2D4" width="1.5rem" height="1.5rem" />

          <p>info@fasta.ai</p>
        </li>
        <li>
          <PhoneIcon fill="#3FC2D4" width="1.5rem" height="1.5rem" />
          <p>3705-1012</p>
        </li>
        <li>
          <LocationIcon fill="#3FC2D4" width="1.5rem" height="1.5rem" />
          <p>2/F, Scribe Corner, 4 Shell Street,<br /> Causeway Bay</p>
        </li>
      </ul>
      <img src="https://lenx-public-assets.s3.amazonaws.com/lenx-ai-website/ContactEnvelop.webp" alt="contact envelop" />
    </DetailsContainer>
  )
}

export default Details
