import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import ServiceCard from "./ServiceCard"

const DigitalServicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 5rem 0px;
  background-color: #E8EDF1;

`

const HeadingTextContainer = styled.div`
  margin-bottom: 7rem;
  text-align: center;

h4 {
    font-size: 34px;
    font-weight: 400;
    margin-bottom: 1.5rem;
  }
`

const ServiceCardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 960px;
  margin-bottom: 7rem;
`

const TryDemoContainer = styled.div`
  display: flex;
  flex-direction: column;

  h4 {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 1.5rem;
  }

  button {
    color: white;
    height: 48px;
    width: 220px;
    border: none;
    background-color: #3FC2D4;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.6) 0.56px 0.83px 4px 0px;
    &:hover {
      background-color: #3FD2C4;
    }
    &:focus {
      outline: none;
    }
    span {
      font-size: 1.2rem;
    }
  }
`

const cards = [
  {
    title: "Intelligent Alert",
    description: "Irrelevant data must be screened out so that you only see the information that is relevant to your brand",
    imageURL: "https://lenx-public-assets.s3.amazonaws.com/lenx-ai-website/IntelligentAlert.webp"
  },
  {
    title: "Auto-Insight",
    description: "Repetitive conversation patterns are analyzed to deepen understanding of emerging social media trends",
    imageURL: "https://lenx-public-assets.s3.amazonaws.com/lenx-ai-website/AutoInsight.webp"
  },
  {
    title: "Forecast Impact",
    description: "A scientific approach for prioritizing communication and action by forecasting the future impact of every crisis.",
    imageURL: "https://lenx-public-assets.s3.amazonaws.com/lenx-ai-website/ForecastImpact.webp"
  }
]


function DigitalServices() {
  return (
    <DigitalServicesContainer>
      <HeadingTextContainer>
        <h4><b>AI-POWERED</b> Digital Services</h4>
        <p>Learn how to scale intelligent interpretation of Social Media Posts at a massive scale</p>
      </HeadingTextContainer>
      <ServiceCardsContainer>
        {cards.map(card => <ServiceCard key={card.title} title={card.title} imageURL={card.imageURL} description={card.description} />)}
      </ServiceCardsContainer>
      <TryDemoContainer>
        <h4>Try AI Monitoring Now</h4>
        <Link to="/contact">
          <button>
            <span>Schedule Demo</span>
          </button>
        </Link>
      </TryDemoContainer>
    </DigitalServicesContainer>
  )
}

export default DigitalServices
