import React from 'react'
import Approach from "./Approach"
import OurTechnology from "./OurTechnology"

function HowItWorks() {
  return (
    <div>
      <Approach />
      <OurTechnology />
    </div>
  )
}

export default HowItWorks
