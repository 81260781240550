import React from "react"
import styled from "styled-components"
import AboutUs from "./AboutUs"
import ContactUs from "./ContactUs"
import Footer from "./Footer"
import Header from "./Header"
import HowItWorks from "./HowItWorks"
import Main from "./Main"
import RequestDemo from "./RequestDemo"

export const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-width: 960;
`

interface LayoutProp {
  type: ContentType
}

export type ContentType = "home" | "how-it-works" | "about-us" | "contact-us"

const Layout = ({ type }: LayoutProp) => {

  const generateContent = (type: ContentType) => {
    switch (type) {
      case "home":
        return <Main />
      case "how-it-works":
        return <HowItWorks />
      case "about-us":
        return <AboutUs />
      case "contact-us":
        return <ContactUs />
      default:
        return <Main />
    }
  }


  const renderDemo = (type: ContentType) => {
    switch (type) {
      case "home":
      case "how-it-works":
        return <RequestDemo />
      case "about-us":
      case "contact-us":
      default:
        return null
    }
  }

  return (
    <LayoutContainer>
      <Header />
      {generateContent(type)}
      {renderDemo(type)}
      <Footer />
    </LayoutContainer>
  )
}

export default Layout
