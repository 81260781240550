import React from 'react'
import styled from 'styled-components'
import NavigationBar from "./NavigationBar"

const Head = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1134px;
  padding-top: 30px;
  padding-bottom: 32px;
`

const Logo = styled.img`
  margin-left: 1rem;
`

function Header() {
  return (
    <Head>
      <Logo src="https://lenx-public-assets.s3.amazonaws.com/lenx-ai-website/LenxLogo.webp" alt="lenx logo" height="48" />
      <NavigationBar />
    </Head>
  )
}

export default Header
