import React from 'react'
import styled from 'styled-components'

const FooterRoot = styled.footer`
  width: 100%;
  color: white;
  background-color: #2C4A74;
  text-align: center;
  padding: 4rem;
  p {
    font-size: 14px;
    margin-bottom: 2rem;
  }
`

const ContactsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;

  img {
    height: 39px;
  }
`

function Footer() {
  return (
    <FooterRoot>
      <p>© Copyright 2019 - 2020   |   All Rights Reserved</p>
      <ContactsContainer>
        <a href="https://www.facebook.com/lenx.ai1">
          <img src="https://lenx-public-assets.s3.amazonaws.com/lenx-ai-website/icon_fb.webp" alt="lenx facebook page" />
        </a>
        <a href="https://www.linkedin.com/company/lenxai/">
          <img src="https://lenx-public-assets.s3.amazonaws.com/lenx-ai-website/icon_linkedin.webp" alt="lenx linkedin page" />
        </a>
        <a href="https://www.instagram.com/thinkcol.hk/?hl=en">
          <img src="https://lenx-public-assets.s3.amazonaws.com/lenx-ai-website/icon_ig.webp" alt="lenx instagram page" />
        </a>
      </ContactsContainer>
    </FooterRoot>
  )
}

export default Footer
