import * as React from "react"
import { IconProp } from "./interface"

function PhoneIcon({ height = "1rem", width = "1rem", fill = "#283044" }: IconProp) {
  return (
    <svg viewBox="0 0 141.73 141.73" height={height} width={width}>
      <path
        d="M137.85 100.29L106.78 87a8.1 8.1 0 00-2.77-.56A6.76 6.76 0 0099 89l-13.85 16.81a102.71 102.71 0 01-49.09-48.9L53 43.1a6.86 6.86 0 002.49-5.25 8 8 0 00-.55-2.49L41.6 4.42A7 7 0 0035.23.27a12.33 12.33 0 01-1.39.28L5 7.18a6.49 6.49 0 00-5 6.35c0 71 57.41 128.2 128.7 128.2a6.51 6.51 0 006.38-5l6.65-28.73v-1.38a7.2 7.2 0 00-3.88-6.33z"
        fill={fill}
      />
    </svg>
  )
}

export default PhoneIcon