import React from 'react'
import { TechnologyImage, TechnologyItemContainer, TechnologyTextDescription } from "."

function ContextualCategorization() {
  return (
    <TechnologyItemContainer>
      <TechnologyImage src="https://lenx-public-assets.s3.amazonaws.com/lenx-ai-website/ContextualCategorization.gif" alt="contextual categorization" />
      <TechnologyTextDescription>
        <h5>Contextual Categorization</h5>
        <p>Instead of relying on a defined set of complex keyword rules to categorizes the social trend forecasts, our platform automatically analyzes the entire context of a post to interpret its topic, sentiment, and impact.</p>
        <ul>
          <li>
            <div>
              <img src="https://lenx-public-assets.s3.amazonaws.com/lenx-ai-website/HowItWorks_Impact.webp" alt="impact" />
            </div>
            <p>Impact - rate the viral factor of the post to the online community</p>
          </li>
          <li>
            <div>
              <img src="https://lenx-public-assets.s3.amazonaws.com/lenx-ai-website/HowItWorks_Sentiment.webp" alt="impact" />
            </div>
            <p>Sentiment - public perception of a brand based on message's context</p>
          </li>
          <li>
            <div>
              <img src="https://lenx-public-assets.s3.amazonaws.com/lenx-ai-website/HowItWorks_Topic.webp" alt="impact" />
            </div>
            <p>Topic - categorize the social post into relevant topics based on what is mentioned</p>
          </li>
        </ul>
      </TechnologyTextDescription>
    </TechnologyItemContainer>
  )
}

export default ContextualCategorization
