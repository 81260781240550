import React from 'react'
import styled from 'styled-components'

const CoverContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 3rem;
  width: 1080px;
  margin-bottom: 4rem;
`

const CoverImage = styled.img`
  width: 45%;
  padding: 1rem;
  min-width: 350px;
  margin-top: -1rem;
`

const CoverDescription = styled.div`
  width: 55%;
  h3 {
    font-size: 36px;
    margin-bottom: 3rem;
  }
  p{
    margin-bottom: 1.5rem;
    line-height: 1.5rem;
  }
`

function Cover() {
  return (
    <CoverContainer>
      <CoverImage src="https://lenx-public-assets.s3.amazonaws.com/lenx-ai-website/AboutUsCover.webp" alt="cover image" />
      <CoverDescription>
        <h3>About Us</h3>
        <p><strong>Lenx.ai</strong> is a Social Analytics platform that helps agencies or enterprises forecast or analyze social media trends for digital marketing or crisis management initiatives.</p>
        <p>Our proprietary technology grants every client with access to a wide range of social media data and a self-service text analytics A.I.-engine that empowers digital marketers to deliver first-class services to their clients on a global scale.</p>
      </CoverDescription>
    </CoverContainer>
  )
}

export default Cover
