import React from 'react'
import styled from 'styled-components'
import BrandRelevantFiltering from "./BrandRelevantFiltering"
import ContextualCategorization from "./ContextualCategorization"
import ControversialForecast from "./ControversialForecast"
import ImproveAccuracy from "./ImproveAccuracy"
import LanguageAdaptation from "./LanguageAdaptation"

const TechnologyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem;
  h3 {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 3rem;
  }
`

export const TechnologyItemContainer = styled.div`
  width: 100%;
  max-width: 960px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 5rem;
`

export const TechnologyImage = styled.img`
  width: 50%;
  padding: 1rem;
`

export const TechnologyTextDescription = styled.div`
  width:50%;
  padding: 1rem;

  h5 {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 2rem;
  }
  p {
    font-size: 1rem;
    letter-spacing: 0rem;
    line-height: 1.8rem;
    margin-bottom: 3rem;
  }
  ul {
    padding: 0px;
    li {
      display: flex;
      margin-bottom: 1.5rem;
      div {
        width: 80px;
        display: flex;
        justify-content: flex-end;
        img {
        height: 44px;
        margin-right: 2rem;
        }
      }
   
      p {
        font-size: 1rem;
        letter-spacing: 0rem;
        line-height: 1.8rem;
        margin-bottom: 0rem;
      }
    }
  }
`

function OurTechnology() {
  return (
    <TechnologyContainer>
      <h3>Our Technology</h3>
      <LanguageAdaptation />
      <BrandRelevantFiltering />
      <ContextualCategorization />
      <ImproveAccuracy />
      <ControversialForecast />
    </TechnologyContainer>
  )
}

export default OurTechnology
