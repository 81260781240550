import React from 'react'
import styled from 'styled-components'

interface ServiceCardProp {
  title: string
  imageURL: string
  description: string
}

const ServiceCardRoot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 250px;
`
const Title = styled.p`
  font-size: 20px;
  text-align: center;
  letter-spacing: 0.15rem;
`

const Description = styled.p`
  font-size: 15px;
  text-align: center;
`

function ServiceCard(props:ServiceCardProp) {
  return (
    <ServiceCardRoot>
      <img src={props.imageURL} height="100" />
      <Title>{props.title}</Title>
      <Description>{props.description}</Description>
    </ServiceCardRoot>
  )
}

export default ServiceCard
