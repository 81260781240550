import React from 'react'
import styled from 'styled-components'

const MediaSourcesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 5rem 0px;
  h4 {
    font-size: 34px;
    margin-bottom: 1rem;
  }
  p {
    font-size: 1rem;
    text-align: center;
    letter-spacing: 0.05rem;
    line-height: 1.5rem;
  }
`

const Image = styled.img`
  margin: 5rem 0px;
`

function MediaSources() {
  return (
    <MediaSourcesContainer>
      <h4>Instantly analyze all media sources in real-time</h4>
      <p>In the era of social media and technology, every minute you leave an online crisis or <br/>opportunities unattended, you will spend twice the effort trying to solve it.</p>
      <Image src="https://lenx-public-assets.s3.amazonaws.com/lenx-ai-website/SocialMediaSources.png" alt="social media sources"/>
      <p>Most advanced data crawling technology in Hong Kong, we combine timeliness and <br/>intelligence in one place</p>
    </MediaSourcesContainer>
  )
}

export default MediaSources
