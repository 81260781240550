import * as React from "react"
import { IconProp } from "./interface"

function LocationIcon({ height = "1rem", width = "1rem", fill = "#283044" }: IconProp) {
  return (
    <svg viewBox="0 0 141.73 141.73" height={height} width={width}>
      <path
        d="M59.23 84.56v45.68l7 9.6a3.75 3.75 0 006 0l7.32-9.6V84.56a63.25 63.25 0 01-10.18.87 57.59 57.59 0 01-10.14-.87zM69.41.64C44 .64 23.61 18.49 23.61 40.2c0 22 20.35 39.56 45.8 39.56 25.13 0 45.81-17.58 45.81-39.56C115.22 18.49 94.54.64 69.41.64zm0 20.88c-12.09 0-21.63 8.48-21.63 18.68 0 1.92-1.91 3.29-3.82 3.29-2.22 0-3.81-1.37-3.81-3.29 0-13.74 13-25.28 29.26-25.28 1.91 0 3.82 1.65 3.82 3.3 0 1.92-1.91 3.3-3.82 3.3z"
        fill={fill}
      />
    </svg>
  )
}

export default LocationIcon