import React from 'react'
import styled from 'styled-components'
import Details from "./Details"
import Form from "./Form"

const ContactContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  h1 {
    font-size: 36px;
    font-weight: 400;
    margin-top: 1rem;
    margin-bottom: 3rem;
  }
  p {
    letter-spacing: 1px;
  }
`

const Layout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-top: 2rem;
  max-width: 800px;
`

function ContactUs() {
  return (
    <ContactContainer>
      <h1>Contact Us</h1>
      <p>Drop us a line at our mailbox, and we'll be happy to get back to you with solutions and ideas.</p>
      <Layout>
        <div>
          <Form />
        </div>
        <div>
          <Details />
        </div>
      </Layout>
    </ContactContainer>
  )
}

export default ContactUs
