import React from 'react'
import { SessionContainer, StyledLink } from "."


function OneClickIntelligence() {
  return (
    <SessionContainer>
      <div>
        <h3>One-click Intelligence</h3>
        <h4>Effortlessly summarize conversations at scale</h4>
        <p>Understand the social media patterns, key influencers, and words associating patterns in just a glance.</p>
        <StyledLink to="/how-it-works">Learn how AI insights are generated</StyledLink>
      </div>
      <div>
        <img src="https://lenx-public-assets.s3.amazonaws.com/lenx-ai-website/OneClickIntelligence.webp" alt="one click intelligence"/>
      </div>
    </SessionContainer>
  )
}

export default OneClickIntelligence
