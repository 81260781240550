import React from 'react'
import styled from 'styled-components'
import { members } from "./members"
import TeamMemberCard from "./TeamMemberCard"

const MeetOurTeamContainer = styled.div`
  margin-bottom: 8rem; 

  h3 {
      font-size: 36px;
      font-weight: 400;
      text-align: center;
      margin-bottom: 4rem;
    }
`

const TeamMembersContainer = styled.div`
   display: grid;
   grid-template-columns: repeat(4, 1fr);
   grid-template-rows: repeat(2, 2fr);
   column-gap: 4rem;
   row-gap: 4rem;
`

function MeetOurTeam() {
  return (
    <MeetOurTeamContainer>
      <h3>Meet Our Team</h3>
      <TeamMembersContainer>
        {members.map(member => <TeamMemberCard name={member.name} position={member.position} photo={member.photo} />)}
      </TeamMembersContainer>
    </MeetOurTeamContainer>
  )
}

export default MeetOurTeam
