import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'

const NavList = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style-type: none;
`

const NavItem = styled.li`
  margin: 0px 15px;
  text-align: right;
  a {
    text-decoration: none;
  }
`

const NavItemText = styled.p <{ isCurrent?: boolean }>`
    color: #7A7979;
    font-weight: 500;
    line-height: 28px;
    text-align: right;
    &:hover {
     color: #969695;
    }
    ${props => props.isCurrent && css`
    color: #3FD2C4;
    &:visited {
      color: #3FD2C4;
    }
  `}
`

function extractPathFromURL(URL: string) {
  const sanitized = URL.replace("//", "")
  const index = sanitized.indexOf("/")
  return sanitized.substring(index + 1).replace("/", "")
}

function NavigationBar() {

  const url = typeof window !== 'undefined' ? window.location.href : ''
  const path = extractPathFromURL(url)

  return (
    <NavList>
      <NavItem>
        <Link to="/" >
          <NavItemText isCurrent={path == ""}>Home</NavItemText>
        </Link>
      </NavItem>
      <NavItem>
        <Link to="/how-it-works" >
          <NavItemText isCurrent={path == "how-it-works"}>How It Works</NavItemText>
        </Link>
      </NavItem>
      <NavItem>
        <Link to="/about-us" >
          <NavItemText isCurrent={path == "about-us"}>About Us</NavItemText>
        </Link>
      </NavItem>
      <NavItem>
        <Link to="/contact" >
          <NavItemText isCurrent={path == "contact"}>Contact Us</NavItemText>
        </Link>
      </NavItem>
      <NavItem>
        <a href="https://medium.com/@fasta-ai">
          <NavItemText>Blog</NavItemText>
        </a>
      </NavItem>
      <NavItem>
        <a href="https://thinkcol.com/">
          <NavItemText>ThinkCol</NavItemText>
        </a>
      </NavItem>
    </NavList>
  )
}

export default NavigationBar
