import React from 'react'
import { TechnologyItemContainer, TechnologyTextDescription, TechnologyImage } from "."

function ImproveAccuracy() {
  return (
    <TechnologyItemContainer>
      <TechnologyTextDescription>
        <h5>Improving accuracy,made simple</h5>
        <p>The accuracy of the model is dependent on the data you use to train it. Thus, we have developed an end-to-end A.I. ecosystem which makes the journey of training and improving your A.I. model a relatively simple process.</p>
      </TechnologyTextDescription>
      <TechnologyImage src="https://lenx-public-assets.s3.amazonaws.com/lenx-ai-website/ImproveAccuracy.webp" alt="improve accuracy" />
    </TechnologyItemContainer>
  )
}

export default ImproveAccuracy
