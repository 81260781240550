import React from 'react'
// import styled from 'styled-components'
import { SessionContainer } from "."

function MatchingKeywords() {
  return (
    <SessionContainer>
      <div>
        <img src="https://lenx-public-assets.s3.amazonaws.com/lenx-ai-website/MatchingKeywords.webp" alt="matching keywords" />
      </div>
      <div>
        <h3>Our technology extends matching keywords</h3>
        <h4>Gain instant access to the best-in-class text and technology</h4>
        <p>We have spent the past three years extracting and analyzing large amounts of online conversation data to enrich our Natural Language Processing technology and the results of this, is now at your fingertips.</p>
      </div>
    </SessionContainer>
  )
}

export default MatchingKeywords
