import React from 'react'
import { SessionContainer } from "."

function MobileAlert() {
  return (
    <SessionContainer>
      <div>
        <img src="https://lenx-public-assets.s3.amazonaws.com/lenx-ai-website/MobileAlert.webp" alt="mobile alert" />
      </div>
      <div>
        <h3>Mobile Alert Capabilities</h3>
        <h4>Get updates instantly, regardless of platform</h4>
        <p>Provide real-time notifications and customized alerts through WhatsApp, meaning you will be notified wherever you are.</p>
      </div>
    </SessionContainer>
  )
}

export default MobileAlert
