import React from 'react'
import styled from 'styled-components'

interface TeamMemberCardProp {
  name: string
  position: string
  photo: string
}

const TeamMemberContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  &:first-child {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 2;
  }
`

const Avatar = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 100%;
  margin-bottom: 1rem;
`

function TeamMemberCard({ name, position, photo }: TeamMemberCardProp) {
  return (
    <TeamMemberContainer>
      <Avatar src={photo} alt={name} />
      <p>{name}</p>
      <p>{position}</p>
    </TeamMemberContainer>
  )
}

export default TeamMemberCard
