import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const CoverContainer = styled.div`
  position: relative;
  width: 100%;
  min-width: 960px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`

const CoverImage = styled.img`
  width: 100%;
  height: 654px;
  object-fit: cover;
`

const AlignContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  position: absolute;
  max-width: 968px;
  width: 100%;
  margin-top: 3.8rem;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  h1 {
    font-size: 3.5rem;
    letter-spacing: 0.02rem;
    line-height: 1.4;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 2rem;
    letter-spacing: 0.05rem;
    line-height: 1.3;
    margin-bottom: 3rem;
  }
`

const Button = styled.button`
  color: white;
  height: 48px;
  width: 220px;
  border: none;
  background-color: #3FC2D4;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.6) 0.56px 0.83px 4px 0px;
  &:hover {
    background-color: #3FD2C4;
  }
  &:focus {
    outline: none;
  }
  span {
    font-size: 1.2rem;
  }
`

function Cover() {
  return (
    <CoverContainer>
      <AlignContainer>
        <TextContainer>
          <h1>Forecast Every Social <br />Trend in Real-Time</h1>
          <p>Automate Digital Services for <br />Any Situations</p>
          <Link to="/how-it-works">
            <Button>
              <span>SEE IT IN ACTION</span>
            </Button>
          </Link>
        </TextContainer>
      </AlignContainer>
      <CoverImage src="https://lenx-public-assets.s3.amazonaws.com/lenx-ai-website/Cover.png" alt="background photo placeholder" />
    </CoverContainer>
  )
}

export default Cover
