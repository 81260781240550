import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const MilestonesContainer = styled.div`
  width: 100%;
  max-width: 960px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0px;

  h3 {
    font-size: 36px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 5rem;
  }

  img {
    width: 760px;
  }

  h4 {
    font-size: 32px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 2rem;
  }

  button {
    margin-bottom: 6rem;
    color: white;
    height: 48px;
    width: 220px;
    border: none;
    background-color: #3FC2D4;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.6) 0.56px 0.83px 4px 0px;
    &:hover {
      background-color: #3FD2C4;
    }
    &:focus {
      outline: none;
    }
    span {
      font-size: 1.2rem;
    }
  }
`

function OurMilestones() {
  return (
    <MilestonesContainer>
      <h3>Our Milestones</h3>
      <img src="https://lenx-public-assets.s3.amazonaws.com/lenx-ai-website/AboutUsMilestones.webp" alt="our milestones" />
      <h4>Be part of our journey now</h4>
      <Link to="/contact">
        <button>
          <span>Get in Touch</span>
        </button>
      </Link>
    </MilestonesContainer>
  )
}

export default OurMilestones
