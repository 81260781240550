import React from 'react'
import { SessionContainer, StyledLink } from "."

function ForecastTrends() {
  return (
    <SessionContainer>
      <div>
        <img src="https://lenx-public-assets.s3.amazonaws.com/lenx-ai-website/ForecastTrends.webp" alt="forecast trends" />
      </div>
      <div>
        <h3>Forecast Trends in Real-Time</h3>
        <h4>Receive more time to build contingency plans </h4>
        <p>Provide a timely forecast of social trend severity with scientific precision, providing a greater response window to make a realistic communication strategy.</p>
        <StyledLink to="/how-it-works">Learn how AI forecast works</StyledLink>
      </div>
    </SessionContainer>
  )
}

export default ForecastTrends
