import React from 'react'
import styled from 'styled-components'
import ClientCard from "./ClientCard"

const ClientsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 3rem 0px;
  width: 100%;
  background-color: #E8EDF1;
  row-gap: 3rem;
`

const CardGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 3rem;
  row-gap: 3rem;
`

const cards = [
  {
    title: "Crisis monitoring for the Airline Industry",
    description: "Around-the-clock monitoring for detecting potential controversial scenarios which may impact brand and public perception.",
    imageURL: "https://lenx-public-assets.s3.amazonaws.com/lenx-ai-website/client_CrisisMonitoring.webp"
  },
  {
    title: "Engage millions of customers at scale",
    description: "Automatically categorise and filter millions of social media comments to empower digital engagement at a massive scale.",
    imageURL: "https://lenx-public-assets.s3.amazonaws.com/lenx-ai-website/client_MillionsCustomers.jpg"
  },
  {
    title: "Academic Research for Hong Kong Baptist University",
    description: "Developing a language agnositc approach to analyze politics-related news posted on social media in 2019.",
    imageURL: "https://lenx-public-assets.s3.amazonaws.com/lenx-ai-website/client_AcademicResearch.webp"
  },
  {
    title: "Financial Regulator Public Perception",
    description: "Identifying public perception of governmental policies and high-profile public figures by analyzing online posts about them.",
    imageURL: "https://lenx-public-assets.s3.amazonaws.com/lenx-ai-website/client_FinancialRegulator.webp"
  },
  {
    title: "Trade-War Impact Analysis",
    description: "Extracting trade-war related news from social media to determine its impact on investment strategy.",
    imageURL: "https://lenx-public-assets.s3.amazonaws.com/lenx-ai-website/client_TradeWar.webp"
  },
  {
    title: "Telegram Risk Monitoring of Financial Institutions",
    description: "Conducting on-going risk assessment of public perception to measure risk of impact to operation.",
    imageURL: "https://lenx-public-assets.s3.amazonaws.com/lenx-ai-website/client_TelegramRiskMonitoring.webp"
  }
]

function OurClients() {
  return (
    <ClientsContainer>
      <h3>Our Clients</h3>
      <CardGridContainer>
        {cards.map(card => <ClientCard key={card.title} title={card.title} description={card.description} imageURL={card.imageURL} />)}
      </CardGridContainer>
    </ClientsContainer>
  )
}

export default OurClients
