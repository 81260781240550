import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const RequestDemoRoot = styled.div`
  width: 100%;
  color: white;
  background-color: #3FC2D4;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem;

  h3 {
    font-size: 36px;
    margin-bottom: 16px;
  }

  p {
    font-size: 20px;
    text-align: center;
    line-height: 2rem;
    margin-bottom: 2rem;
  }
`

const RequestButton = styled.button`
  background-color: white;
  color: #3FC2D4;
  width: 256px;
  height: 52px;
  box-shadow: rgba(0, 0, 0, 0.6) 0.48px 0.87px 4px 0px;
  border: none;
  cursor: pointer;
  transition: all ease-in 0.2s;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #6DD8C5;
    color: white;
  }

  span {
    font-size: 16px;
    font-weight: 500;
  }
`

function RequestDemo() {
  return (
    <RequestDemoRoot>
      <h3>Deep Dive with Lenx.ai</h3>
      <p>See how our services and technology can help with <br />your digital transformation.</p>
      <Link to="/contact">
        <RequestButton>
          <span>REQUEST DEMO</span>
        </RequestButton>
      </Link>
    </RequestDemoRoot>
  )
}

export default RequestDemo
