import React from 'react'
import { TechnologyItemContainer, TechnologyImage, TechnologyTextDescription } from "."

function ControversialForecast() {
  return (
    <TechnologyItemContainer>
      <TechnologyImage src="https://lenx-public-assets.s3.amazonaws.com/lenx-ai-website/ControversialForecast.webp" alt="controversial forecast" />
      <TechnologyTextDescription>
        <h5>Controversial Forecast</h5>
        <p>By analyzing the relevant context, data source, and histrical social trends of every message, we can forecast consumer reaction and sentiments, providing</p>
        <ul>
          <li>
            <div>
              <img src="https://lenx-public-assets.s3.amazonaws.com/lenx-ai-website/howItWorks_Point.webp" alt="point" />
            </div>
            <p>Early prediction of whether a post is likely to generate controversy</p>
          </li>
          <li>
            <div>
              <img src="https://lenx-public-assets.s3.amazonaws.com/lenx-ai-website/howItWorks_Point.webp" alt="point" />
            </div>
            <p>Warning of extreme emotions - whether people are for or against a social post</p>
          </li>
          <li>
            <div>
              <img src="https://lenx-public-assets.s3.amazonaws.com/lenx-ai-website/howItWorks_Point.webp" alt="point" />
            </div>
            <p>A list of topics which customers strongly agree or disagree with</p>
          </li>
        </ul>
      </TechnologyTextDescription>
    </TechnologyItemContainer>
  )
}

export default ControversialForecast
