import React from 'react'
import { TechnologyImage, TechnologyItemContainer, TechnologyTextDescription } from "."



function LanguageAdaptation() {
  return (
    <TechnologyItemContainer>
      <TechnologyImage src="https://lenx-public-assets.s3.amazonaws.com/lenx-ai-website/LanguageAdaptation.webp" alt="language adaptation" />
      <TechnologyTextDescription>
        <h5>Language Adaptation</h5>
        <p>Every industry contains its own industry-specific lingual. Our technology enables a self-learning process that seamlessly learns the latest slang found on social media channels.</p>
      </TechnologyTextDescription>
    </TechnologyItemContainer>
  )
}

export default LanguageAdaptation
