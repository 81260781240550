import React from 'react'
import { SessionContainer, StyledLink } from "."

function CustomizedAI() {
  return (
    <SessionContainer>
      <div>
        <h3>Customized AI Automation</h3>
        <h4>Intelligent Categorization for every conversation</h4>
        <p>Automatically categorize every post by sentiment, impact, and topic based on your own definition.</p>
        <StyledLink to="/how-it-works">Learn how AI categorization works</StyledLink>
      </div>
      <div>
        <img src="https://lenx-public-assets.s3.amazonaws.com/lenx-ai-website/CustomizedAI.webp" alt="customize ai automation" />
      </div>
    </SessionContainer>
  )
}

export default CustomizedAI
