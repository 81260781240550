import React from 'react'
import styled from 'styled-components'

interface ClientCardProp {
  title: string
  description: string
  imageURL: string
}

const CardBack = styled.div`
  width: 284px;
  height: 100%;
  padding: 1.2rem;
  background-color: #303F4D;
  color: white;
  position: absolute;
  visibility: hidden;
  h4 {
    margin-bottom: 1.5rem;
  }

  p {
    letter-spacing: 0.07rem;
  }
`

const CardFront = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;

  img {
    width: 100%;
    height: 120px;
    object-fit: cover;
  }
  
  div {
    width: 100%;
    height: calc(100% - 120px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.2rem;
    h4 { 
      font-size: 18px;
      line-height: 1.5rem;
      font-weight: 400;
      letter-spacing: 0.05rem;
    }
  }
`

const CardRoot = styled.div`
  position: relative;
  width: 284px;
  height: 230px;
  &:hover {
    ${CardBack} {
      visibility: visible;
    }
    ${CardFront} {
      visibility: hidden;
    }
  }
`



function ClientCard(props: ClientCardProp) {
  return (
    <CardRoot>
      <CardBack>
        <h4>{props.title}</h4>
        <p>{props.description}</p>
      </CardBack>
      <CardFront>
        <img src={props.imageURL} alt={props.title} />
        <div>
          <h4>{props.title}</h4>
        </div>
      </CardFront>
    </CardRoot>
  )
}

export default ClientCard
