export const members = [
  {
    name: "Sam Ho",
    position: "Product Lead",
    photo: "https://lenx-public-assets.s3.amazonaws.com/lenx-ai-website/avatars/Avatar_Sam.webp"
  },
  {
    name: "Kat Chan",
    position: "Project Manager",
    photo: "https://lenx-public-assets.s3.amazonaws.com/lenx-ai-website/avatars/Avatar_Kat.webp"
  },
  {
    name: "Austin Chang",
    position: "Developer Lead",
    photo: "https://lenx-public-assets.s3.amazonaws.com/lenx-ai-website/avatars/Avatar_Austin.webp"
  },
  {
    name: "Bryanna Chan",
    position: "Data Engineer",
    photo: "https://lenx-public-assets.s3.amazonaws.com/lenx-ai-website/avatars/Avatar_Bryanna.webp"
  },
  {
    name: "Juno Ng",
    position: "Frontend Developer",
    photo: "https://lenx-public-assets.s3.amazonaws.com/lenx-ai-website/avatars/Avatar_Juno.webp"
  },
  {
    name: "Castiel Tong",
    position: "Data Scientist",
    photo: "https://lenx-public-assets.s3.amazonaws.com/lenx-ai-website/avatars/Avatar_Castiel.webp"
  },
  {
    name: "Rudy Cheung",
    position: "Data Scientist",
    photo: "https://lenx-public-assets.s3.amazonaws.com/lenx-ai-website/avatars/Avatar_Rudy.webp"
  },
  {
    name: "Alison Chiu",
    position: "UX Designer",
    photo: "https://lenx-public-assets.s3.amazonaws.com/lenx-ai-website/avatars/Avatar_Alison.webp"
  },
  {
    name: "Joanne Ng",
    position: "Operations Lead",
    photo: "https://lenx-public-assets.s3.amazonaws.com/lenx-ai-website/avatars/Avatar_Joanne.webp"
  }
]