import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import CustomizedAI from "./CustomizedAI"
import ForecastTrends from "./ForecastTrends"
import MatchingKeywords from "./MatchingKeywords"
import MobileAlert from "./MobileAlert"
import OneClickIntelligence from "./OneClickIntelligence"

const TechnologyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 4rem 0px;
`

const SessionHeading = styled.h4`
  font-size: 28px;
  font-weight: 400;
  margin-bottom: 3rem;
  text-align: center;
  line-height: 2.4rem;
`

export const SessionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 960px;
  column-gap: 2rem;
  margin-bottom: 5rem;

  div {
    width: 50%;

    h3 {
      font-size: 34px;
      margin-bottom: 1.5rem;
      /* line-height: 1.5rem; */
    }

    h4 {
      font-size: 22px;
      margin-bottom: 1rem;
      line-height: 1.7rem;
    }

    p {
      font-size: 17px;
      line-height: 1.8rem;
    }

    img {
      width: 100%;
    }
  }
`

export const StyledLink = styled(Link)`
  color: #3FC2D4;
`


function AITechnology() {
  return (
    <TechnologyContainer>
      <SessionHeading>DATA-DRIVEN AI TECHNOLOGY</SessionHeading>
      <MatchingKeywords />
      <SessionHeading>Improving the Efficiency of Digital Transformations <br />with AI Technology</SessionHeading>
      <OneClickIntelligence />
      <ForecastTrends />
      <CustomizedAI />
      <SessionHeading>NOT ANOTHER SOCIAL LISTENING PLATFORM, <br />WE PUT INSIGHTS RIGHT WHERE YOU BELONG</SessionHeading>
      <MobileAlert />
    </TechnologyContainer>
  )
}

export default AITechnology
