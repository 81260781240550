import React from 'react'
import styled from 'styled-components'

const ApproachRoot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 5rem;
  text-align: center;

  h1 {
    margin-bottom: 1.8rem;
    letter-spacing: 0.1rem;
    font-weight: 300;
  }

  p {
    font-size: 15px;
    margin-bottom: 6rem;
    letter-spacing: 0.1rem;
    line-height: 1.5rem;
  }

  img {
    width: 100%;
    max-width: 1068px;
  }
`

function Approach() {
  return (
    <ApproachRoot>
      <h1><strong>A.I. and Data-Centric Approach</strong> to Digital Transformation</h1>
      <p>Leveraging AI creates a scalable approach that detects, understands, and prioritizes online digital <br />services in an unparalleled way.</p>
      <img src="https://lenx-public-assets.s3.amazonaws.com/lenx-ai-website/HowItWorksCover.webp" alt="how it works" />
    </ApproachRoot>
  )
}

export default Approach
