import React from 'react'
import styled from 'styled-components'
import Cover from "./Cover"
import MeetOurTeam from "./MeetOurTeam"
import OurMilestones from "./OurMilestones"

const AboutUsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

function AboutUs() {
  return (
    <AboutUsContainer>
      <Cover />
      <MeetOurTeam />
      <OurMilestones />
    </AboutUsContainer>
  )
}

export default AboutUs
