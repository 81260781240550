import React, { useState } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { industries } from "./industries"
import ArrowDownIcon from "../../assets/icons/ArrowDownIcon"


const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 5rem;
  h3 {
    font-size: 28px;
    font-weight: 400;
    margin-bottom: 2rem;
  }
`

const Input = styled.input`
  width: 350px;
  height: 60px;
  border: 2px solid grey;
  margin-bottom: 0.8rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  &:focus {
    outline: none;
    border: 2px solid grey;
  }
  &:hover {
    background-color: #F2FAF9;
    border: 2px solid #484848;
  }
`

const TextArea = styled.textarea`
  width: 350px;
  border: 2px solid grey;
  margin-bottom: 0.8rem;
  padding: 0.5rem;
  resize: none;
  &:focus {
    outline: none;
    border: 2px solid grey;
  }
  &:hover {
    background-color: #F2FAF9;
    border: 2px solid #484848;
  }
`

const Select = styled.select`
  width: 350px;
  height: 60px;
  border: 2px solid grey;
  margin-bottom: 0.8rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  -moz-appearance: none; // Firefox
  -webkit-appearance: none; // Safari and Chrome
  appearance: none;
  cursor: pointer;
  &:hover {
    background-color: #F2FAF9;
    border: 2px solid #484848;
  }
  &:focus {
    outline: none;
    background-color: #F2FAF9;
    border: 2px solid #484848;
  }
`

const SendButton = styled.input`
  width: 350px;
  height: 46px;  
  color: white;
  border: none;
  background-color: #3FC2D4;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.6) 0.56px 0.83px 4px 0px;
  &:hover {
    background-color: #3FD2C4;
  }
  &:focus {
    outline: none;
  }
  span {
    font-size: 1.2rem;
  }
`

const SelectContainer = styled.div`
  position: relative;
`

const ArrowWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`

function Form() {

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [industry, setIndustry] = useState("")
  const [role, setRole] = useState("")
  const [message, setMessage] = useState("")
  const [submitStatus, setSubmitStatus] = useState("SEND")

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
  }

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  const handleIndustryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setIndustry(e.target.value)
  }

  const handleRoleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRole(e.target.value)
  }

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value)
  }

  const setDefault = () => {
    setName("")
    setEmail("")
    setIndustry("")
    setRole("")
    setMessage("")
  }

  const sendForm = async (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    e.preventDefault()
    try {
      await axios({
        url: "https://svc.lenx.ai/website-emailer/email",
        method: "post",
        data: {
          name, email, industry, role, message
        }
      })
      setSubmitStatus("SENT")
      setDefault()
    } catch (e) {
      setSubmitStatus("TRY AGAIN")
      console.error(e)
    }
  }

  return (
    <FormContainer>
      <h3>Get in touch</h3>
      <Input placeholder="Your name" name="name" value={name} onChange={handleNameChange} />
      <Input placeholder="Your e-mail" name="email" value={email} onChange={handleEmailChange} />
      <SelectContainer>
        <Select value={industry} onChange={handleIndustryChange}>
          <option value="" disabled hidden>Interested Industry</option>
          {industries.map(industry => <option key={industry} value={industry}>{industry}</option>)}
        </Select>
        <ArrowWrapper>
          <ArrowDownIcon />
        </ArrowWrapper>
      </SelectContainer>
      <Input placeholder="Your role" name="role" value={role} onChange={handleRoleChange} />
      <TextArea placeholder="Leave us a message and let us know how we can help" rows={5} value={message} onChange={handleMessageChange} />
      <SendButton type="submit" value={submitStatus} onClick={sendForm} />
    </FormContainer>
  )
}

export default Form
