import * as React from "react"
import { IconProp } from "./interface"

function ArrowDownIcon({ height = "1rem", width = "1rem", fill = "#283044" }: IconProp) {
  return (
    <svg viewBox="0 0 141.73 141.73" height={height} width={width}>
      <path
        d="M74.6 108.19h.05l6.49-6.5L139.9 42.9a5.36 5.36 0 00-.09-7.57l-6.72-6.71a5.2 5.2 0 00-7.35-.09L70.58 83.7 15.9 29a5.29 5.29 0 00-7.48-.09l-6.65 6.66a5.15 5.15 0 00.09 7.27l58.62 58.61 6.6 6.61a5.29 5.29 0 007.52.13z"
        fill={fill}
      />
    </svg>
  )
}

export default ArrowDownIcon