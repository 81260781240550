import React from 'react'
import { TechnologyImage, TechnologyItemContainer, TechnologyTextDescription } from "."

function BrandRelevantFiltering() {
  return (
    <TechnologyItemContainer>
      <TechnologyTextDescription>
      <h5>Brand-Relevant Filtering</h5>
      <p>Just because a message contains the name of a company doesn't mean the message itself is relevant to the brand. Our algorithm not only filters social media data based on the underlying context of the message but determines its relevancy to a social trend.</p>
      </TechnologyTextDescription>
      <TechnologyImage src="https://lenx-public-assets.s3.amazonaws.com/lenx-ai-website/BrandRelevantFiltering.gif" alt="brand relevant filtering" />
    </TechnologyItemContainer>
  )
}

export default BrandRelevantFiltering
